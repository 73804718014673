.wpo-features-area {
  position: relative;
  padding: 100px 0 70px;
}

.fetures-img {
  position: absolute;
  left: 8%;
  top: 14%;
}

.fetures-img img {
  max-width: 100%;
}

.wpo-features-icon {
  margin-bottom: 18px;
}

.feature-icon3 {
  margin-bottom: 10px;
}

.wpo-section-title2 h2:before {
  left: 0;
}

.wpo-section-title2 h2 {
  margin-bottom: 30px;
}

.wpo-features-text p {
  padding-top: 10px;
}

.wpo-features-text h3 {
  font-size: 25px;
}

.wpo-features-icon2 {
  transform: rotate(45deg);
  display: inline-block;
}

.wpo-feature-wrap {
  margin-bottom: 35px;
}

.wpo-features-area {
  position: relative;
}

.wpo-features-icon {
  display: inline-block;
}

.wpo-features-area:before {
  content: "";
  position: absolute;
  left: 14%;
  top: 27%;
  width: 71%;
  height: 64%;
  background: url("../images/features-bg.png") no-repeat center center / cover;
  z-index: 0;
}

.wpo-features-area .wpo-features-icon,
.wpo-features-area .wpo-features-icon2,
.wpo-features-area .feature-icon3 {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  line-height: 75px;
  text-align: center;
  color: #fff;
}

.wpo-features-area .wpo-features-item .fi {
  color: #fff;
}

.wpo-features-area .feature-icon3 {
  margin-bottom: 10px;
  display: inline-block;
}

.wpo-features-area .wpo-feature-img {
  text-align: center;
}