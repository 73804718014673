:root {
  --fadeInTime: 0.5s;
}

.wpo-calculator-area,
.wpo-calculator-style-2,
.wpo-calculator-style-3 {
  padding: 100px 0;
}

.wpo-calculator-content h2 {
  font-size: 30px;
  font-weight: 700;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 30px;
}

.sec-p {
  padding-left: 30px;
}

.wpo-calculator-img img {
  padding-top: 50px;
}

.wpo-calculator-content p {
  margin-bottom: 30px;
}

.wpo-calculator-content .wpo-calculator-icon i:before {
  font-size: 60px;
}

.signature-section {
  overflow: hidden;
}

.si-text {
  margin-top: 20px;
}

.si-text p {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.signature-section img {
  float: right;
}

.CalculatorSection-carousel {
  z-index: 0;
}

.wpo-calculator-img img {
  padding-top: 0;
}

.fade-in-image {
  animation: fadeIn var(--fadeInTime);
  -webkit-animation: fadeIn var(--fadeInTime);
  -moz-animation: fadeIn var(--fadeInTime);
  -o-animation: fadeIn var(--fadeInTime);
  -ms-animation: fadeIn var(--fadeInTime);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
