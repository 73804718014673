:root {
  --fadeInTime: 0.5s
}

.wpo-contact-area,
.wpo-contact-style-2,
.wpo-contact-style-3 {
  padding: 100px 0;
}

.wpo-contact-content h2 {
  font-size: 30px;
  font-weight: 700;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 30px;
}

.sec-p {
  padding-left: 30px;
}

.wpo-contact-img img {
  padding-top: 50px;
}

.wpo-contact-content p {
  margin-bottom: 30px;
}

.wpo-contact-content .wpo-contact-icon i:before {
  font-size: 60px;
}

.signature-section {
  overflow: hidden;
}

.si-text {
  margin-top: 20px;
}

.si-text p {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.signature-section img {
  float: right;
}

.contactSection-carousel {
  z-index: 0;
}

.wpo-contact-img img {
  padding-top: 0;
}

.fade-in-image {
  animation: fadeIn var(--fadeInTime);
  -webkit-animation: fadeIn var(--fadeInTime);
  -moz-animation: fadeIn var(--fadeInTime);
  -o-animation: fadeIn var(--fadeInTime);
  -ms-animation: fadeIn var(--fadeInTime);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.wpo-contact-area,
.wpo-contact-s2,
.contact-style-3 {
  padding: 100px 0 70px;
  /* background: #fafafa; */
}

.contact-section-title h2 {
  font-size: 36px;
  font-weight: 700;
  /* color: #333; */
  /* padding-bottom: 20px; */
  /* margin-block: 20px; */
  position: relative;
}

.flex {
  display: flex;
}

.wpo-section-title- h2:before {
  content: "";
  position: absolute;
  left: 47%;
  bottom: 0;
  width: 60px;
  height: 3px;
}

.wpo-contact-single {
  overflow: hidden;
}

.wpo-contact-single {
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  margin-bottom: 30px;
  /* background-color: aquamarine; */
}

.wpo-contact-single:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(20, 36, 64, 0.2); */
}

.wpo-contact-area .wpo-contact-item .wpo-contact-single:before {
  content: none;
}

.wpo-contact-itemm {
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  margin: auto;
  /* margin-bottom: 25px; */
}

.wpo-contact-iconn {
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  margin: auto;
  /* margin-bottom: 25px; */
  color: #ffffff;
}

.wpo-contact-s2 .wpo-contact-item .wpo-contact-single .wpo-contact-icon i,
.contact-style-3 .wpo-contact-item .wpo-contact-single .wpo-contact-icon i {
  color: #ffffff;
}

.wpo-contact-s2 .wpo-contact-item .wpo-contact-single h2,
.contact-style-3 .wpo-contact-item .wpo-contact-single h2 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.wpo-contact-s2 .wpo-contact-item .wpo-contact-single p,
.contact-style-3 .wpo-contact-item .wpo-contact-single p {
  line-height: 30px;
  color: #5d5b5b;
  margin-bottom: 0;
}

.wpo-contact-s2 .wpo-contact-item .wpo-contact-single {
  background: #fff;
  text-align: center;
  padding: 40px 33px;
}

.wpo-contact-s2 .wpo-contact-single:before {
  display: none;
}

.link-container {
  display: flex;
  justify-content: center;
}